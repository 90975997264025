import React, { useEffect, useRef } from 'react'
import styles from './MenuHeader.module.sass'
import LanguageSelector from '../LanguageSelector'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons'
import links from '../../store/DataJSON/links.json'
import { useDispatch, useSelector } from 'react-redux'
import { changeTheme } from '../../store/slices/userSlice'

function MenuHeader ({ menu, setMenu }) {
  const { language, theme } = useSelector(state => state.userStore.data)
  const recordsHeader = useSelector(state => state.recordStore.recordsHeader)
  const page = useSelector(state => state.recordStore.page)
  const dispatch = useDispatch()
  const menuRef = useRef(null)

  const handleThemeChange = newTheme => {
    if (theme !== newTheme) {
      dispatch(changeTheme({ theme: newTheme }))
    }
  }

  const closeMenuOnClickOutside = e => {
    if (
      menuRef.current &&
      !menuRef.current.contains(e.target) &&
      !e.target.closest('.menuBtn')
    ) {
      setMenu(false)
    }
  }

  useEffect(() => {
    if (menu) {
      document.addEventListener('mousedown', closeMenuOnClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', closeMenuOnClickOutside)
    }
  })

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1100) {
        setMenu(false)
      }
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [setMenu])

  return (
    <div className={styles.openMenu} ref={menuRef}>
      <div className={styles.navigationBox}>
        <p className={styles.title}>
          {recordsHeader['header']?.['header-box-title1']?.[language]}
        </p>
        <div className={styles.line}></div>
        <div className={styles.box}>
          {links &&
            links.navigations &&
            Object.entries(links.navigations).map(([key, item], index) => (
              <div
                href={item.url}
                key={index}
                className={`${styles.hoverBtn} ${
                  page === item.page ? styles.activeBtn : ''
                }`}
              >
                {recordsHeader['header']?.[key]?.[language]}
              </div>
            ))}
        </div>
      </div>
      <div className={styles.settingsBox}>
        <p className={styles.title}>
          {recordsHeader['header']?.['header-box-title2']?.[language]}
        </p>
        <div className={styles.line}></div>
        <div className={styles.flexBox}>
          <div
            onClick={() =>
              handleThemeChange(theme === 'light' ? 'dark' : 'light')
            }
            className={styles.themeBox}
          >
            <span className={styles.themeText}>
              {recordsHeader['header']?.['theme-selector']?.[language]}
            </span>
            {theme === 'light' ? (
              <FontAwesomeIcon className={styles.icon} icon={faSun} />
            ) : (
              <FontAwesomeIcon className={styles.icon} icon={faMoon} />
            )}
          </div>
          <div className={styles.languageBox}>
            <span className={styles.languageText}>
              {recordsHeader['header']?.['language-selector']?.[language]}
            </span>
            <LanguageSelector />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MenuHeader
