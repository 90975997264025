import React, { createContext, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeLanguage } from '../store/slices/userSlice'
import { fetchLanguages } from '../store/slices/languageSlice'
import { fetchRecords } from '../store/slices/recordSlice'

export const LanguageContext = createContext()

export const LanguageProvider = ({ children }) => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.userStore)
  const languages = useSelector(state => state.languageStore.languages)
  const languageError = useSelector(state => state.languageStore.error)
  const records = useSelector(state => state.recordStore.records)
  const recordsHeader = useSelector(state => state.recordStore.recordsHeader)
  const recordsFooter = useSelector(state => state.recordStore.recordsFooter)
  const page = useSelector(state => state.recordStore.page)
  const recordError = useSelector(state => state.recordStore.error)
  const recordHeaderError = useSelector(state => state.recordStore.errorHeader)
  const recordFooterError = useSelector(state => state.recordStore.errorFooter)

  const { language } = user.data

  useEffect(() => {
    if (languages.length === 0 && !languageError) {
      dispatch(fetchLanguages())
    }
  }, [dispatch, languages.length, languageError])

  const hasTranslation = useMemo(() => {
    const checkTranslation = (records, tagGroup) =>
      !!(
        records[tagGroup] &&
        records[tagGroup][Object.keys(records[tagGroup])[0]] &&
        records[tagGroup][Object.keys(records[tagGroup])[0]][language]
      )

    return {
      content: checkTranslation(records, page),
      header: checkTranslation(recordsHeader, 'header'),
      footer: checkTranslation(recordsFooter, 'footer')
    }
  }, [language, records, recordsHeader, recordsFooter, page])

  useEffect(() => {
    if (!!page && !hasTranslation.content && !recordError) {
      dispatch(fetchRecords({ tagGroup: page, language }))
    }
  }, [dispatch, hasTranslation.content, language, page, recordError])

  useEffect(() => {
    if (!hasTranslation.header && !recordHeaderError) {
      dispatch(fetchRecords({ tagGroup: 'header', language }))
    }
  }, [dispatch, hasTranslation.header, language, recordHeaderError])

  useEffect(() => {
    if (!hasTranslation.footer && !recordFooterError) {
      dispatch(fetchRecords({ tagGroup: 'footer', language }))
    }
  }, [dispatch, hasTranslation.footer, language, recordFooterError])

  const handleChangeLanguage = newLanguage => {
    if (language !== newLanguage) {
      dispatch(changeLanguage({ language: newLanguage }))
    }
  }

  return (
    <LanguageContext.Provider
      value={{
        language,
        languages,
        toggleLanguage: handleChangeLanguage,
        hasTranslation
      }}
    >
      {children}
    </LanguageContext.Provider>
  )
}
