import React, { useEffect, useRef } from 'react'
import styles from './MenuAccount.module.sass'
import CONSTANTS from '../../constants'
// import links from '../../store/DataJSON/links.json'
// import { useDispatch, useSelector } from 'react-redux'

function MenuAccount ({ menu, user, setMenu, signOut }) {
  // const { language } = useSelector(state => state.userStore.data)
  // const recordsHeader = useSelector(state => state.recordStore.recordsHeader)
  // const dispatch = useDispatch()
  const menuRef = useRef(null)

  const closeMenuOnClickOutside = e => {
    if (
      menuRef.current &&
      !menuRef.current.contains(e.target) &&
      !e.target.closest('.userBtn')
    ) {
      setMenu(false)
    }
  }

  useEffect(() => {
    if (menu) {
      document.addEventListener('mousedown', closeMenuOnClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', closeMenuOnClickOutside)
    }
  })

  return (
    <div className={styles.openMenu} ref={menuRef}>
      <div className={styles.account}>
        <img
          className={styles.userImg}
          src={`${user?.picture}`}
          alt='User.png'
        />
        <div className={styles.userInfo}>
          {user?.username && (
            <p className={styles.userNickName}>{user?.username}</p>
          )}
          {user?.name && (
            <p
              className={
                !user?.username ? styles.userNickName : styles.userUserDesc
              }
            >
              {user?.name}
            </p>
          )}
          {user?.email ? (
            <p className={styles.userUserDesc}>{user?.email}</p>
          ) : (
            user?.phone_number && (
              <p className={styles.userUserDesc}>{user?.phone_number}</p>
            )
          )}
        </div>
      </div>
      <div className={styles.line}></div>
      <div className={styles.menuBtns}>
        <div className={styles.menuBtn}>
          <img className={styles.icon} src='/images/Home.png' alt='Home.png' />
          <p className={styles.textBtn}>Домашняя страница</p>
        </div>
        <div className={styles.menuBtn}>
          <img
            className={styles.icon}
            src='/images/Credit_card.png'
            alt='Credit_card.png'
          />
          <p className={styles.textBtn}>Финансы</p>
        </div>
        <div className={styles.menuBtn}>
          <img className={styles.icon} src='/images/Star.png' alt='Star.png' />
          <p className={styles.textBtn}>Список желаемого</p>
        </div>
        <div className={styles.menuBtn}>
          <img
            className={styles.icon}
            src='/images/Handshake.png'
            alt='Handshake.png'
          />
          <p className={styles.textBtn}>Преподавайте у нас</p>
        </div>
      </div>
      <div className={styles.line}></div>
      <div className={styles.menuBtns}>
        <div className={styles.menuBtn}>
          <img
            className={styles.icon}
            src='/images/Setting.png'
            alt='Setting.png'
          />
          <p className={styles.textBtn}>Настройки</p>
        </div>
        <div
          className={styles.menuBtn}
          onClick={() => signOut(`${CONSTANTS.REACT_APP_URL_MAINPAGE}/`)}
        >
          <img className={styles.icon} src='/images/Exit.png' alt='Exit.png' />
          <p className={styles.textBtn}>Выйти</p>
        </div>
      </div>
    </div>
  )
}

export default MenuAccount
