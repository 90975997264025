import axios from 'axios'
import CONSTANTS from '../constants.js'

export const fetchLanguages = async () =>
  await axios.get(`${CONSTANTS.REACT_APP_URL_MAINPAGE_BFF}/api/language`)

export const fetchRecordsByTagGroupAndLanguage = async ({
  tagGroup,
  language
}) =>
  await axios.get(
    `${CONSTANTS.REACT_APP_URL_MAINPAGE_BFF}/api/record/translation/${tagGroup}/${language}`
  )

export const fetchJsonData = async () =>
  await axios.get(`${CONSTANTS.REACT_APP_URL_MAINPAGE_BFF}/api/jsondata`)
