import React, { useEffect, useState } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import styles from './WhatWeAchieved.module.sass'
import { connect, useSelector } from 'react-redux'

function WhatWeAchieved ({ user, records, page }) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const { language } = user.data
  const contentList = useSelector(
    state => state.jsonDataStore.jsonData.achieves
  )

  useEffect(() => {
    AOS.init({
      duration: 1000
    })

    const handleResize = () => {
      setScreenWidth(window.innerWidth)
      AOS.refresh()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const getAnimation = index => {
    if (screenWidth >= 1701) {
      if (
        index === 0 ||
        index === 1 ||
        index === 2 ||
        index === 5 ||
        index === 6 ||
        index === 7
      ) {
        return 'fade-left'
      } else {
        return 'fade-right'
      }
    } else {
      if (index % 2 === 0) {
        return 'fade-right'
      } else {
        return 'fade-left'
      }
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h5 className={styles.title}>
          {records[page]?.['what-we-achieved-title']?.[language]}
        </h5>
        <div className={styles.contentList}>
          {contentList &&
            contentList.map((value, index) => (
              <div
                key={value.img}
                className={styles.listItem}
                data-aos={getAnimation(index)}
              >
                <div className={styles.imgBox}>
                  <img
                    className={styles.img}
                    src={`/images/${value.img}`}
                    alt={value.img}
                  />
                </div>
                <p className={styles.text}>
                  {records[page]?.[value.text]?.[language]}
                </p>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  user: state.userStore,
  records: state.recordStore.records,
  page: state.recordStore.page
})

export default connect(mapStateToProps, null)(WhatWeAchieved)
