import React, { useEffect } from 'react'
import NotFound from '../../components/NotFound'
import { useDispatch, useSelector } from 'react-redux'
import { changePage } from '../../store/slices/recordSlice'

function NotFoundPage () {
  const dispatch = useDispatch()
  const page = useSelector(state => state.recordStore.page)

  useEffect(() => {
    if (page !== 'notfoundpage') {
      dispatch(changePage({ page: 'notfoundpage' }))
    }
  }, [dispatch, page])

  return (
    <>
      <NotFound />
    </>
  )
}

export default NotFoundPage
