import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as API from '../../api'

export const fetchLanguages = createAsyncThunk(
  'languages/fetchLanguages',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await API.fetchLanguages()
      return data
    } catch (err) {
      const errorData = {
        status: err?.response?.status ?? 504,
        errors:
          err?.response?.data?.errors?.length > 0
            ? err.response.data.errors
            : ['Gateway Timeout'],
        message:
          err?.response?.data?.message ??
          'Failed to load languages. Please try again later'
      }
      return rejectWithValue({ data: errorData })
    }
  }
)

const initialState = {
  languages:
    JSON.parse(localStorage.getItem('languages'))?.expiresAt > Date.now()
      ? JSON.parse(localStorage.getItem('languages')).data
      : [],
  isFetching: false,
  error: null
}

const languageSlice = createSlice({
  name: 'languages',
  initialState,
  reducers: {
    clearLanguageError: (state, action) => {
      state.error = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchLanguages.pending, state => {
        state.isFetching = true
        state.error = null
      })
      .addCase(fetchLanguages.fulfilled, (state, action) => {
        state.isFetching = false
        state.languages = action.payload
        localStorage.setItem(
          'languages',
          JSON.stringify({
            data: state.languages,
            addedAt: Date.now(),
            expiresAt: Date.now() + 3 * 24 * 60 * 60 * 1000
          })
        )
      })
      .addCase(fetchLanguages.rejected, (state, action) => {
        state.isFetching = false
        state.error = action.payload?.data ?? action.error.message
      })
  }
})

const { actions, reducer } = languageSlice

export const { clearLanguageError } = actions

export default reducer
