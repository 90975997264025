import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import deepMerge from '../../utils/deepMerge'
import * as API from '../../api'

export const fetchRecords = createAsyncThunk(
  'records/fetchRecordsByPageAndLanguage',
  async ({ tagGroup, language }, { rejectWithValue }) => {
    try {
      const { data } = await API.fetchRecordsByTagGroupAndLanguage({
        tagGroup,
        language
      })
      return { data, tagGroup }
    } catch (err) {
      const errorData = {
        status: err?.response?.status ?? 504,
        errors:
          err?.response?.data?.errors?.length > 0
            ? err.response.data.errors
            : ['Gateway Timeout'],
        message:
          err?.response?.data?.message ??
          `Failed to load ${tagGroup} translations. Please try again later`
      }
      return rejectWithValue({ data: errorData, tagGroup })
    }
  }
)

const initialState = {
  records:
    JSON.parse(localStorage.getItem('records'))?.expiresAt > Date.now()
      ? JSON.parse(localStorage.getItem('records')).data
      : {},
  recordsHeader:
    JSON.parse(localStorage.getItem('recordsHeader'))?.expiresAt > Date.now()
      ? JSON.parse(localStorage.getItem('recordsHeader')).data
      : {},
  recordsFooter:
    JSON.parse(localStorage.getItem('recordsFooter'))?.expiresAt > Date.now()
      ? JSON.parse(localStorage.getItem('recordsFooter')).data
      : {},
  page: '',
  isFetching: false,
  error: null,
  errorHeader: null,
  errorFooter: null
}

const reducers = {
  clearRecordError: (state, action) => {
    state[action.payload] = null
  },
  changePage: (state, action) => {
    state.page = action.payload.page
  }
}

const recordSlice = createSlice({
  name: 'record',
  initialState,
  reducers,
  extraReducers: builder => {
    builder
      .addCase(fetchRecords.pending, state => {
        state.isFetching = true
        state.error = null
      })
      .addCase(fetchRecords.fulfilled, (state, action) => {
        state.isFetching = false
        const recordKey =
          {
            header: 'recordsHeader',
            footer: 'recordsFooter'
          }[action.payload.tagGroup] || 'records'
        const records = deepMerge(state[recordKey], action.payload.data)
        state[recordKey] = records
        const dataToStore = {
          data: records,
          addedAt: Date.now(),
          expiresAt: Date.now() + 3 * 24 * 60 * 60 * 1000
        }
        localStorage.setItem(recordKey, JSON.stringify(dataToStore))
      })
      .addCase(fetchRecords.rejected, (state, action) => {
        state.isFetching = false
        const recordKey =
          {
            header: 'errorHeader',
            footer: 'errorFooter'
          }[action.payload.tagGroup] || 'error'
        state[recordKey] = action.payload?.data ?? action.error.message
      })
  }
})

const { actions, reducer } = recordSlice

export const { clearRecordError, changePage } = actions

export default reducer
