import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { changePage } from '../../store/slices/recordSlice'
import Banner from '../../components/Banner'
import ScrollBanner from '../../components/ScrollBanner'
import WhatYouCanFind from '../../components/WhatYouCanFind'
import ReviewList from '../../components/ReviewsList'
import QandA from '../../components/Q&A'
import WhatWeAchieved from '../../components/WhatWeAchieved'
import ComeToUs from '../../components/ComeToUs'
import { fetchJsonData } from '../../store/slices/jsonDataSlice'

function HomePage () {
  const dispatch = useDispatch()
  const page = useSelector(state => state.recordStore.page)
  const jsonData = useSelector(state => state.jsonDataStore.jsonData)
  const error = useSelector(state => state.jsonDataStore.error)

  useEffect(() => {
    AOS.init({ duration: 1000 })
    if (page !== 'mainpage') {
      dispatch(changePage({ page: 'mainpage' }))
    }
  }, [dispatch, page])

  useEffect(() => {
    if (error === null && Object.keys(jsonData).length === 0) {
      dispatch(fetchJsonData())
    }
  }, [dispatch, error, jsonData])

  return (
    <>
      <Banner />
      <ScrollBanner />
      <WhatYouCanFind />
      <ReviewList />
      <WhatWeAchieved />
      <ComeToUs />
      <QandA />
    </>
  )
}

export default HomePage
