import React, { useState, useEffect } from 'react'
// import { useLogto, IdTokenClaims } from '@logto/react' //Тут тебе надо перейти к исходному типу IdTokenClaims и посмотреть какие у него есть поля для извлечения значений из них (F12 или Ctrl + B), если он больше не нужен - стереть его
import { useLogto } from '@logto/react'
import { useSelector } from 'react-redux'
import WaveDown from '../../components/WaveDown'
import MenuHeader from '../MenuHeader'
import styles from './Header.module.sass'
import links from '../../store/DataJSON/links.json'
import CONSTANTS from '../../constants'
import MenuAccount from '../MenuAccount'

//Этим мы будем извлекать кастомные данные о юзере которые нам могут понадобиться
// const { fetchUserInfo } = useLogto();
//
// const userInfo = await fetchUserInfo();

function Header () {
  const { signIn, signOut, isAuthenticated, getIdTokenClaims } = useLogto()
  const [user, setUser] = useState() //user использовать для извлечения данных
  const { language } = useSelector(state => state.userStore.data)
  const recordsHeader = useSelector(state => state.recordStore.recordsHeader)
  const page = useSelector(state => state.recordStore.page)
  const [menu, setMenu] = useState(false)
  const [menuAccount, setMenuAccount] = useState(false)

  useEffect(() => {
    ;(async () => {
      if (isAuthenticated) {
        const claims = await getIdTokenClaims()
        setUser(claims)
      }
    })()
  }, [getIdTokenClaims, isAuthenticated])

  return (
    <header className={styles.header}>
      <WaveDown />
      <div className={styles.navigation}>
        <div className={styles.logo}>
          <a
            href={links?.buttons?.['logo-btn']?.url ?? '/'}
            className={styles.imgBox}
          >
            <img src='/images/LearnHub_logo.png' alt='LearnHub_logo' />
          </a>
        </div>
        <div className={styles.navigationBtn}>
          {links &&
            links.navigations &&
            Object.entries(links.navigations).map(([key, item], index) => (
              <a
                href={item.url}
                key={index}
                className={`${styles.hoverBtn} ${
                  page === item.page ? styles.activeBtn : ''
                }`}
              >
                {recordsHeader['header']?.[key]?.[language]}
              </a>
            ))}
        </div>
        <div className={styles.menuBox}>
          <div
            className={`${styles.userBtn} userBtn`}
            onClick={() => {
              if (isAuthenticated && !!user) {
                setMenuAccount(prev => !prev)
              }
            }}
          >
            <img
              className={styles.userImg}
              src={
                isAuthenticated && user?.picture
                  ? `${user?.picture}`
                  : '/images/User_fill.png'
              }
              alt='User.png'
            />
            <span
              className={styles.userText}
              onClick={async () => {
                if (!isAuthenticated && !user) {
                  signIn(`${CONSTANTS.REACT_APP_URL_MAINPAGE}/callback`)
                }
              }}
            >
              {!isAuthenticated && !user
                ? `${recordsHeader['header']?.['login']?.[language]}`
                : user?.username ?? user?.name}
              {/* Переводить текст, signOut(http://localhost:3000/) - метод выхода (в проде за основу url брать домен из env или откуда ты его там берешь, так же сообщить мне чтобы я их подменил в логто ибо из коробки с подменой только тут работать не будет) */}
            </span>
          </div>
          <div
            className={`${styles.menuBtn} menuBtn`}
            onClick={() => setMenu(prev => !prev)}
          >
            <img
              className={styles.menuIcon}
              src='/images/IconMenu.png'
              alt='IconMenu.png'
            />
          </div>
        </div>
        {menu && <MenuHeader menu={menu} setMenu={setMenu} />}
        {isAuthenticated && !!user && menuAccount && (
          <MenuAccount
            menu={menuAccount}
            user={user}
            setMenu={setMenuAccount}
            signOut={signOut}
          />
        )}
      </div>
    </header>
  )
}

export default Header
