import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
// import { LogtoProvider, LogtoConfig } from '@logto/react' //LogtoConfig пока что не стирать, этот тип возможно будет нужен в будущем
import { LogtoProvider } from '@logto/react'
import HomePage from './pages/HomePage'
import Layout from './components/Layout'
import NotFoundPage from './pages/NotFoundPage'
import CallbackPage from './pages/CallbackPage'
import CONSTANTS from './constants.js'

const config = {
  endpoint: CONSTANTS.REACT_APP_LOGTO_ENDPOINT,
  appId: CONSTANTS.REACT_APP_LOGTO_APPID,
  scopes: ['email', 'openid', 'profile', 'offline_access']
}

function App () {
  return (
    <LogtoProvider config={config}>
      <Router>
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={<HomePage />} />
            <Route path='*' element={<NotFoundPage />} />
            <Route path='/callback' element={<CallbackPage />} />
          </Route>
        </Routes>
      </Router>
    </LogtoProvider>
  )
}

export default App
