import React from 'react'
import { useSelector } from 'react-redux'
import styles from './WhatYouCanFind.module.sass'
import CategoryBox from '../CategoryBox'
import links from '../../store/DataJSON/links.json'

function WhatYouCanFind () {
  const user = useSelector(state => state.userStore)
  const records = useSelector(state => state.recordStore.records)
  const page = useSelector(state => state.recordStore.page)
  const { language } = user.data
  const listCategory = useSelector(
    state => state.jsonDataStore.jsonData.directions
  )

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h4 className={styles.title}>
          {records[page]?.['what-you-can-find-title']?.[language]}
        </h4>
        <div className={styles.listCategory}>
          {listCategory &&
            listCategory.map((category, index) => (
              <CategoryBox
                key={`${category.name} ${index}`}
                category={category}
                index={index}
              />
            ))}
        </div>
        <div className={styles.flexBox}>
          <a
            href={
              links?.buttons?.['what-you-can-find-btn-see-more']?.url ?? '/'
            }
            className={styles.seeMoreBtn}
          >
            <span className={styles.btnText}>
              {records[page]?.['what-you-can-find-btn-see-more']?.[language]}
            </span>
          </a>
        </div>
      </div>
    </div>
  )
}

export default WhatYouCanFind
